import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';

function ScrollTracker(props) {
  const { data, next,loaded,wentBack } = props;

 

  useEffect(()=>{
    if(loaded && wentBack){
      const clicked_post = sessionStorage.getItem('clicked_post')
      scroller.scrollTo(clicked_post, {
        duration: 100,
        delay: 100,
      });
    }
  },[loaded])

  return (
    <div/>
  );
}

export default ScrollTracker;

