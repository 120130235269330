import React from 'react';


function NoContentMessage({ customMessage = "No se encontró contenido" , isLoading = false}) {
    return (
        <div className={isLoading ? 'NoContentMessage LoadingMessage' : 'NoContentMessage'}>
            <p>{customMessage}</p>
            {isLoading && 
                <img  src={require('../../assets/oficial_icons/loading_dots.gif')} alt="loading" />
            }
        </div>
    );
}

export default NoContentMessage;